@import '../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.icon {
    height: 16px;
}

.button {
    /* width: 120px; */
    composes: p from global;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    color: var(--marketplaceColor);
    display: inline;

    &:hover {
        cursor: pointer;
    }
}